import { api } from '../axios'

const WebRTCCredentialsService = (() => {
  const fetchAllCredentials = async () => {
    const res = await api.get(`/v1/web-rtc-credential`)
    return res.data
  }

  const createCredential = async data => {
    const res = await api.post(`/v1/web-rtc-credential`, data)
    return res.data
  }
  const updateCredential = async (id, data) => {
    const res = await api.put(`/v1/web-rtc-credential/${id}`, data)
    return res.data
  }
  const restoreCredential = async id => {
    const res = await api.patch(`/v1/web-rtc-credential/${id}/restore`)
    return res.data
  }
  const deleteCredential = async id => {
    const res = await api.delete(`/v1/web-rtc-credential/${id}`)
    return res.data
  }

  return {
    fetchAllCredentials,
    createCredential,
    restoreCredential,
    updateCredential,
    deleteCredential
  }
})()

export default WebRTCCredentialsService
