<template>
  <div
    class="d-flex justify-content-center align-items-center gap-2 p-2 border rounded"
    role="button"
    @click="copyApiKey()"
  >
    <span class="api-key">{{ rowData.apiKey.slice(0, 20) }}...</span>
    <i class="fa fa-clipboard" aria-hidden="true"></i>
  </div>
</template>

<script>
export default {
  name: 'apiKey',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  methods: {
    copyApiKey() {
      this.copyToClipboard(this.rowData.apiKey)
    }
  }
}
</script>
<style lang="scss" scoped>
.gap-2 {
  gap: 0.5rem;
}
.api-key {
  overflow-wrap: anywhere;
}
</style>
