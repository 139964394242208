<template>
  <div class="isDeleted" :class="statusColor">
    {{
      rowData.isDeleted
        ? $t('developer-tab.shared.deleted')
        : $t('developer-tab.shared.active')
    }}
  </div>
</template>

<script>
export default {
  name: 'isDeleted',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusColor() {
      return this.rowData.isDeleted ? 'deleted' : 'active'
    }
  }
}
</script>
<style lang="scss" scoped>
.isDeleted {
  font-weight: bold;
  text-transform: uppercase;
  &.deleted {
    color: red;
  }
  &.active {
    color: green;
  }
}
</style>
