<template>
  <form @submit.prevent="saveCredentials">
    <div class="d-flex flex-column gap-2 mb-3">
      <div
        class="d-flex align-items-start gap-2"
        v-for="(_domain, idx) in form.domains"
        :key="`domain-${idx}`"
      >
        <Field
          type="text"
          class="flex-fill"
          v-model="form.domains[idx]"
          :error="errors[`domains[${idx}]`]"
        />
        <button
          type="button"
          class="px-3 py-2 btn btn-danger"
          @click="removeDomain(idx)"
          :disabled="form.domains.length === 1"
        >
          <i class="fa fa-trash"></i>
        </button>
      </div>
      <button
        type="button"
        class="px-3 border w-100 btn btn-sm"
        @click="addDomain"
      >
        {{ $t('developer-tab.webrtc-credentials.edit-form.form.add-domain') }}
      </button>
    </div>
    <button class="w-100 btn btn-primary btn-sm" type="submit">
      {{ $t('developer-tab.webrtc-credentials.edit-form.form.update') }}
    </button>
  </form>
</template>

<script>
import { array, object, string } from 'yup'
import Field from '../../../../../elements/Field.vue'
import WebRTCCredentialsService from '../../../../../services/webrtcCredentials.service'
import { yupToKV } from '../../../../../utils/yup'

export default {
  components: {
    Field
  },
  computed: {
    editSchema() {
      return object().shape({
        domains: array().of(
          string()
            .trim()
            .required(
              this.$t(
                'developer-tab.webrtc-credentials.edit-form.form.domain.required'
              )
            )
        )
      })
    }
  },
  props: {
    currentCredential: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        domains: ['']
      },
      errors: {}
    }
  },
  methods: {
    addDomain() {
      this.form.domains = [...this.form.domains, '']
    },
    removeDomain(index) {
      this.form.domains = this.form.domains.filter((_, idx) => idx !== index)

      this.errors[`domains["${index}"]`] = undefined
    },
    async saveCredentials() {
      try {
        this.errors = {}
        await this.editSchema
          .validate(this.form, { abortEarly: false })
          .catch(err => {
            this.errors = yupToKV(err)
            console.log(this.errors, this.form)
          })

        if (this.editSchema.isValidSync(this.form)) {
          const res = await WebRTCCredentialsService.updateCredential(
            this.currentCredential.id,
            { domains: this.form.domains }
          )
          this.toast(
            this.$t('developer-tab.webrtc-credentials.edit-form.toasts.success')
          )
          this.$emit('submit')
        }
      } catch (error) {
        console.log(error)
        this.toast(error.response.data.message, { type: 'error' })
      } finally {
      }
    }
  },
  watch: {
    currentCredential: {
      handler() {
        this.form.domains = [...this.currentCredential.domains]
      },
      deep: true
    }
  }
}
</script>

<style scoped lang="scss">
.gap-2 {
  gap: 0.5rem;
}
</style>
