<template>
  <div class="d-flex flex-wrap justify-content-center gap-2 domains">
    <b-badge variant="primary" v-for="domain in rowData.domains" :key="domain">
      {{ domain }}
    </b-badge>
  </div>
</template>

<script>
export default {
  name: 'domains',
  props: {
    rowData: {
      type: Object,
      required: true
    }
  }
}
</script>
<style lang="scss" scoped>
.gap-2 {
  gap: 0.5rem;
}
</style>
